<template>
  <div id="app" style="max-width: 500px!important;">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>


export default {
  name: "App",
  components: {
  },
};
</script>

<style>
  #app{
    height: 100%;
    width: 100%!important;
    margin: 0 auto;
  }
</style>
